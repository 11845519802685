export enum ApplicationPrivileges {
  None = 0,
  DashboardHealthView = 1,
  DashboardWaterView = 2,
  DashboardNutrientsView = 3,
  DashboardSiteSummaryWaterUseView = 4,
  SiteView = 5,
  SiteStatusView = 6,
  SiteForecastPlanningView = 7,
  SiteObservationsNutrientsView = 8,
  SiteObservationsHealthView = 9,
  SiteMapView = 10,
  SiteMapHealthView = 11,
  SiteMapWeatherStationView = 12,
  SiteMapSoilMoistureView = 13,
  SiteMapWaterSampleView = 14,
  SiteWeatherView = 15,
  SiteWaterView = 16,
  SiteWaterPlanAppliedView = 17,
  SiteNutrientsView = 18,
  SiteHealthView = 19,
  SiteSettingsSoilView = 20,
  SiteSettingsCropView = 21,
  SiteSettingsCropCoefficientView = 22,
  SiteSettingsCropToleranceView = 23,
  SiteSettingsWaterView = 24,
  SiteSettingsWaterWettedAreaView = 25,
  SiteSettingsWaterEmitterLossesView = 26,
  SiteSettingsWaterIrrigationSystemEfficiencyView = 27,
  SiteSettingsWaterApplicationRateView = 28,
  SiteSettingsNutrientsView = 29,
  SiteSettingsWeatherView = 30,
  SiteLogsDownloadView = 31,
  GroupView = 32,
  GroupSummaryGroupStatusView = 33,
  GroupSummaryNutrientsView = 34,
  GroupSiteSummaryWaterUseView = 35,
  GroupWaterPlanningView = 36,
  GroupWaterReportsView = 37,
  GroupNutrientApplicationView = 38,
  GroupNutrientReportsView = 39,
  GroupSettingsSoilView = 40,
  GroupSettingsCropView = 41,
  GroupSettingsCropCoefficientView = 42,
  GroupSettingsCropToleranceView = 43,
  GroupSettingsWaterView = 44,
  GroupSettingsWaterWettedAreaView = 45,
  GroupSettingsWaterEmitterLossesView = 46,
  GroupSettingsWaterIrrigationSystemEfficiencyView = 47,
  GroupSettingsWaterApplicationRateView = 48,
  GroupSettingsNutrientsView = 49,
  GroupSettingsWeatherView = 50,
  GroupLogsDownloadView = 51,
  EquipmentView = 52,
  EquipmentObservationsView = 53,
  EquipmentSettingsView = 54,
  EquipmentLogsDownloadView = 55,
  ContactsView = 56,
  ContactsPriceListView = 57,
  LogsDownloadView = 58,
  WaterLicenceView = 59,
  WaterBudgetView = 60,
  NutrientsProgramsView = 61,
  NutrientsSamplingView = 62,
  NutrientsFertilisersView = 63,
  NutrientsAnalytesView = 64,
  NutrientsProfilesView = 65,
  AdminSystemAccountsView = 66,
  AdminSystemUsersView = 67,
  AdminAccountView = 68,
  AdminAccountTimingsView = 69,
  AdminUsersView = 70,
  SiteWaterReportsView = 71,
  SiteNutrientReportsView = 72,
  LogsView = 73,
  AdminCropLibraryView = 74,
  NetworkView = 75,
  IrrigationPlanView = 76,
  IrrigationPlanFull = 77,
  IrrigationPlanSetting = 78,
  WaterBudgetClean = 79,
  WaterBudgetAdd = 80,
  WaterBudgetApply = 81,
  AlertView = 82,
  AlertFull = 83,

  SiteFull = 1000,
  SiteStatusFull = 1001,
  SiteMapFull = 1002,
  SiteMapHealthFull = 1003,
  SiteMapWeatherStationFull = 1004,
  SiteMapSoilMoistureFull = 1005,
  SiteMapWaterSampleFull = 1006,
  SiteWaterFull = 1007,
  SiteWaterPlanAppliedFull = 1008,
  SiteNutrientsFull = 1009,
  SiteHealthFull = 1010,
  SiteSettingsSoilFull = 1011,
  SiteSettingsCropFull = 1012,
  SiteSettingsCropCoefficientFull = 1013,
  SiteSettingsCropToleranceFull = 1014,
  SiteSettingsWaterFull = 1015,
  SiteSettingsWaterWettedAreaFull = 1016,
  SiteSettingsWaterEmitterLossesFull = 1017,
  SiteSettingsWaterIrrigationSystemEfficiencyFull = 1018,
  SiteSettingsWaterApplicationRateFull = 1019,
  SiteSettingsNutrientsFull = 1020,
  SiteSettingsWeatherFull = 1021,
  GroupFull = 1022,
  GroupSummaryGroupStatusFull = 1023,
  GroupWaterPlanningFull = 1024,
  GroupNutrientApplicationFull = 1025,
  GroupSettingsSoilFull = 1026,
  GroupSettingsCropFull = 1027,
  GroupSettingsCropCoefficientFull = 1028,
  GroupSettingsCropToleranceFull = 1029,
  GroupSettingsWaterFull = 1030,
  GroupSettingsWaterWettedAreaFull = 1031,
  GroupSettingsWaterEmitterLossesFull = 1032,
  GroupSettingsWaterIrrigationSystemEfficiencyFull = 1033,
  GroupSettingsWaterApplicationRateFull = 1034,
  GroupSettingsNutrientsFull = 1035,
  GroupSettingsWeatherFull = 1036,
  EquipmentFull = 1037,
  EquipmentObservationsFull = 1038,
  EquipmentSettingsFull = 1039,
  EquipmentSettingsFileUploadFull = 1040,
  ContactsFull = 1041,
  ContactsPriceListFull = 1042,
  WaterLicenceFull = 1043,
  WaterBudgetFull = 1044,
  NutrientsProgramsFull = 1045,
  NutrientsSamplingFull = 1046,
  NutrientsFertilisersFull = 1047,
  NutrientsAnalytesFull = 1048,
  NutrientsProfilesFull = 1049,
  AdminSystemAccountsFull = 1050,
  AdminSystemUsersFull = 1051,
  AdminAccountFull = 1052,
  AdminUsersFull = 1053,
  AdminCropLibraryFull = 1055,
  NetworkFull = 1056,
  GroupSiteAreaFull = 1057,
  GroupSettingsWaterIrrigatedAreaFull = 1058,
  SiteNutrientsPlannedFieldFull = 1059,
  SiteNutrientsAppliedFieldFull = 1060,
  EquipmentSettingsValueRangeFull = 1061,
  SiteSettingsWaterIrrigatedAreaFull = 1062,
  SiteSettingDelete = 1063,
  IrrigationControllerFull = 1064,
  IrrigationControllerView = 1065,
  IrrigationControllerAdd = 1066,
  CropMetricsFull = 1067,
  CropMetricsView = 1068,
  CropCyclesView = 1069,
  CropCyclesFull = 1070,
  CustomUnitsFull = 1071,
  ImpactModuleReportsView = 1072,
  IrrigationOverrideView = 1073,
  InfrastructureView = 1074,
  InfrastructureFull = 1075,
  AllocationView = 1076,
  AllocationFull = 1077,
}
