import { ObsWeatherRollup } from 'src/app/_DBContext/ObsWeatherRollup';
import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '../enums';

export class ConvertedWeather {
  Rainfall: number;
  EToLong: number;
  EToShort: number;
  TemperatureMax: number;
  TemperatureMin: number;
  TemperatureMean: number;
  SolarRadiation: number;
  HumidityMax: number;
  HumidityMean: number;
  HumidityMin: number;
  WindSpeedMean: number;
  dayDate: Date;
  dayDisplayYMD: string;
}

export class WeatherConverter {
  public static convert(service: UnitOfMeasureService, data: ObsWeatherRollup[], size: unitSizes): ConvertedWeather[] {
    const newItems: ConvertedWeather[] = [];
    data.forEach((item) => {
      const newItem = new ConvertedWeather();
      newItem.Rainfall = service.convertFromBase('Fluid Depth', size, item.Rainfall);
      newItem.EToLong = service.convertFromBase('Fluid Depth', size, item.EToLong);
      newItem.EToShort = service.convertFromBase('Fluid Depth', size, item.EToShort);
      newItem.TemperatureMax = service.convertFromBase('Temperature', size, item.TemperatureMax);
      newItem.TemperatureMin = service.convertFromBase('Temperature', size, item.TemperatureMin);
      newItem.TemperatureMean = service.convertFromBase('Temperature', size, item.TemperatureMean);
      newItem.SolarRadiation = service.convertFromBase('Solar Radiation', size, item.SolarRadiation);
      newItem.HumidityMax = item.HumidityMax;
      newItem.HumidityMean = item.HumidityMean;
      newItem.HumidityMin = item.HumidityMin;
      newItem.WindSpeedMean = service.convertFromBase('Velocity', size, item.WindSpeedMean);
      newItem.dayDate = item.dayDate;
      newItem.dayDisplayYMD = item.dayDisplayYMD;
      newItems.push(newItem);
    });

    return newItems;
  }
}
