import { ArrayUtils } from '@indicina/swan-shared/utils/ArrayUtils';
import { FilterService } from '@services/filter.service';
import { LanguageService } from '@services/language.service';

export class EntityList<EntityType extends fuse.listAssetDto | fuse.listEntityDto> {
  // Functionality for paginated/selectable list of assets - more recently genericised to any listable/filterable entity
  // Provides support for filtering by translations of database category, eg. equipment type
  // Defining here in attempt to strip out some of the standard functions & variables used in many of our list controllers

  // Now also contains functionality from tableControl (in combination with listFilter), to apply column based filter/sort functions
  // Filter keys may also be provided in the following formats:
  //      obj.child.name - Filters on item['obj']['child']['name'], eg. item.site.name = 'South Field'
  //      field:<=       - Filters where field <= (filtervalue). Also works for >, >=, <, eg. item.length < 5
  //      field:list     - Filters where field value is any of an array of values, eg. item.siteId in [100,200,201]
  //      field:=        - Filters where field value == filter (ie. excludes partial string matches, which are matched by default)

  public entities = [] as EntityType[];

  public currentPage = 1;
  public pageSize = 20;
  public total: number;
  public selectAll: boolean;

  public filterService: FilterService;
  public active = 'Active';
  public archived = 'Archived';
  public suspended = 'Suspended';
  public filters = {};
  public statusFilters = FilterService.DefaultStatusFilter;

  public nameField: string = 'name';
  public statusField: string = 'status';
  public idField: string = 'id';
  public archivedCount = 0;
  public activeCount = 0;
  public suspendedCount = 0;
  private nonStatusFilter = 'nonStatusFilter'; // key used to record filter result by any property other than status

  public orderByField: string;
  public reverseSort: boolean = false;

  private translatedFields = {};

  constructor(entities: EntityType[], filterService: FilterService = null) {
    this.entities = entities || [];
    this.mapIds();
    this.setTypeCounters();

    if (!filterService) {
      return;
    }

    this.filterService = filterService;

    if (filterService.keepFilter && filterService.filters) {
      this.filters = this.filterService.filters;
      this.statusFilters = this.filterService.statusFilters;
    } else {
      this.saveFilters();
    }
  }

  // Extends functionality to datatypes with id/name or Id/Name instead of assetId/assetName
  public mapIds() {
    if (this.entities?.length < 1) {
      return;
    }

    const entity = this.entities[0];

    if (entity['assetId'] && entity['assetName']) {
      return;
    }

    this.idField = !!entity['assetId'] ? 'assetId' : !!entity['id'] ? 'id' : !!entity['Id'] ? 'Id' : null;
    this.nameField = !!entity['assetName'] ? 'assetName' : !!entity['name'] ? 'name' : !!entity['Name'] ? 'Name' : null;
    this.statusField = !!entity['status'] ? 'status' : !!entity['Status'] ? 'Status' : null;
  }

  public setTypeCounters() {
    this.archivedCount = 0;
    this.activeCount = 0;
    this.suspendedCount = 0;

    this.entities.forEach((info) => {
      info.selected = false;

      // If object doesn't match other current filters, don't count it
      if (info[this.nonStatusFilter]) {
        switch (info.status) {
          case this.archived:
            this.archivedCount++;
            break;
          case this.suspended:
            this.suspendedCount++;
            break;
          default:
            this.activeCount++;
        }
      }
    });
  }

  public tagNonActive(languageService: LanguageService) {
    this.entities.forEach((entity) => {
      if (entity.status !== this.active) {
        entity['name'] = languageService.instant(`COMMON.${entity.status.toUpperCase()}_PREFIX`, { name: entity['name'] });
      }
    });
  }

  public getActiveOnly() {
    return this.entities.filter((en) => en.status == this.active);
  }

  // Creates '[Field]Trans' property with translated value.
  public setTranslation(languageService: LanguageService, field: string, db_category: string) {
    this.translatedFields[field] = db_category;
    this.updateTranslations(languageService);
  }

  /** Must be called after any translated DB field has been updated to update '[Field]Trans' version */
  public updateTranslations(languageService: LanguageService) {
    Object.keys(this.translatedFields).forEach((field) => {
      const newField = field + 'Trans';
      const db_category = this.translatedFields[field];

      this.entities.forEach((entity) => {
        entity[newField] = languageService.instant(`DB_VALUES.${db_category.toLocaleUpperCase()}.${entity[field]}`);
      });
    });
  }

  public getStatusClass(status: string) {
    if (status === this.active) {
      return 'icon-checkbox-marked-circle swanGreen';
    } else if (status === this.archived) {
      return 'icon-cancel red-500-fg';
    } else {
      return 'icon-minus-circle swanOrange';
    }
  }

  public calculateStartFrom(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  public setOrderByColumn(column: string) {
    if (this.orderByField !== column) {
      this.reverseSort = false;
    } else {
      this.reverseSort = !this.reverseSort;
    }

    this.orderByField = column;
  }

  public toggleButton(filterName: string): void {
    if (this.statusFilters) {
      this.statusFilters[filterName] = !this.statusFilters[filterName];
    } else {
      this.filters[filterName] = !this.filters[filterName];
    }
  }

  public clearFilters() {
    Object.keys(this.filters).forEach((key) => {
      this.filters[key] = '';
    });

    this.saveFilters();
  }

  public clearFiltersExcept(filtersToKeep: string | string[]) {
    const toKeep = ArrayUtils.toArray(filtersToKeep);

    Object.keys(this.filters).forEach((key) => {
      if (!toKeep.some((f) => f === key)) {
        this.filters[key] = '';
      }
    });

    this.saveFilters();
  }

  public saveFilters() {
    if (!this.filterService) {
      return;
    }

    this.filterService.filters = this.filters;
    this.filterService.statusFilters = this.statusFilters;
  }

  public filterEntities() {
    const filtered = this.entities.filter((entity) => this.filterData(entity));

    return filtered;
  }

  // Filtering by multiple fields, including translations. Used by 'entityList' pipe.
  public filterData(item, index = null, array = null) {
    const filterKeys = Object.keys(this.filters);

    item[this.nonStatusFilter] = true;

    // Apply text filters
    for (let i = 0; i < filterKeys.length; i++) {
      const filterKey = filterKeys[i];
      const filterValue = this.filters[filterKey];

      if (!filterValue) {
        continue;
      }

      if (filterKey.includes(':')) {
        if (!this.relativeFilter(item, filterKey, filterValue)) {
          item[this.nonStatusFilter] = false; // don't include this item in active/archived/suspended counts

          return false;
        }
      } else {
        let targetValue = this.getValue(item, filterKey) as string;

        if (!targetValue) {
          return false;
        }

        if (typeof filterValue !== 'string' && targetValue !== filterValue) {
          return false;
        }

        targetValue = targetValue.toLocaleLowerCase();

        const filterText = filterValue.toLocaleLowerCase();

        if (!targetValue.includes(filterText)) {
          item[this.nonStatusFilter] = false; // don't include this item in active/archived/suspended counts

          return false;
        }
      }
    }

    // Apply active/archived/suspended status filters - do this last so nonStatusFilter populated first
    if (item.status && !this.statusFilters[item.status]) {
      return false;
    }

    return true;
  }

  public getValue(item, filterKey: string) {
    let value = item[filterKey];

    if (filterKey.includes('.')) {
      value = item;

      // Get the value by traversing the object for a 'filterKey' with 'dot' notation.
      const keys = filterKey.split('.');

      keys.forEach((k) => {
        value = value[k];
      });
    }

    return value;
  }

  public relativeFilter(entity, key: string, filterValue) {
    let op;

    [key, op] = key.split(':');

    const value = this.getValue(entity, key);

    if (!filterValue) {
      return true;
    }

    switch (op) {
      case '>':
        return value > filterValue;
      case '<':
        return value < filterValue;
      case '>=':
        return value >= filterValue;
      case '<=':
        return value <= filterValue;
      case '=':
        return value == filterValue;
      case 'list':
        return (filterValue as any[]).indexOf(value) > -1;
    }

    return false;
  }

  public nameExists = false;

  public checkNameExists(entity) {
    if (!this.entities.length) {
      return false;
    }

    const lcase = entity[this.nameField].toLocaleLowerCase();

    this.nameExists = this.entities.some((en) => en[this.nameField].toLowerCase() == lcase && en[this.idField] !== entity[this.idField]);
  }
}
