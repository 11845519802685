import { HttpUtils } from '@indicina/swan-shared/utils/HttpUtils';
import { SWANConstants } from '@common/SWANConstants';
import { SWANConstantsEnum } from '../enums';

export class CommonHelper {
  static baseRoute = '/angular/';

  public static UI = {
    SplashScreen: {
      hide: ($rootScope: angular.IRootScopeService) => $rootScope.$broadcast('msSplashScreen::hide'),
      remove: ($rootScope: angular.IRootScopeService) => $rootScope.$broadcast('msSplashScreen::remove'),
      show: ($rootScope: angular.IRootScopeService) => $rootScope.$broadcast('msSplashScreen::show'),
    }
  };

  public static getApiUrl(route: string): string {
    const apiBaseUrl = SWANConstants.apiBaseUrl;

    if (!apiBaseUrl) {
      throw new Error('Please ensure `apiBaseUrl` is set in SWANConstants!');
    }

    return `${HttpUtils.removeTrailingSlash(SWANConstants.apiBaseUrl)}/${route}`;
  }

  public static isBaseRoute(): boolean {
    return window.location.pathname === CommonHelper.baseRoute;
  }

  public static isRoutePath(routePath: string): boolean {
    const targetPath = `${HttpUtils.removeTrailingSlash(CommonHelper.baseRoute)}/${routePath}`;

    return window.location.pathname === targetPath;
  }

  public static isRedirectFromAuthentication(): boolean {
    return window.location.href.includes('access_token');
  }

  public static getRoutePath(): string {
    return window.location.pathname.replace(CommonHelper.baseRoute, '');
  }

  public static IsValidPositiveNumber(value: string): boolean {
    return SWANConstants.RegexDecimalOrWholePositiveNumber.test(value);
  }

  public static IsValidIntegerPositiveNumber(value: string): boolean {
    return SWANConstants.RegexIntegerPositiveNumber.test(value);
  }

  public static IsValidCSVDateInput(value: string): boolean {
    return SWANConstants.RegexCSVDateFormat.test(value);
  }

  public static FormatPermissionData(data: any): any {
    let result = data;
    if (typeof data == 'string') {
      result = JSON.parse(data);
    }
    return result;
  }

  public static setNavActiveItemForState(navigationTargetState: string, navCustomActiveBackgroundColor: string): void {
    CommonHelper.resetBackgrounColorForAllNavItems();

    let elementId = navigationTargetState.replace('app.', '');

    while (!!elementId) {
      const navElement = this.findNavElement(elementId);

      if (navElement) {
        (navElement as HTMLElement).style.backgroundColor = navCustomActiveBackgroundColor ?? SWANConstantsEnum.navDefaultActiveBackgroundColor;

        break;
      } else {
        elementId = elementId.substring(0, elementId.lastIndexOf('.'));
      }
    }
  }

  public static setNavActiveItemForElement(elementId: string, navCustomActiveBackgroundColor: string): void {
    CommonHelper.resetBackgrounColorForAllNavItems();

    const navElement = this.findNavElement(elementId);

    if (navElement) {
      navElement.style.backgroundColor = navCustomActiveBackgroundColor ?? SWANConstantsEnum.navDefaultActiveBackgroundColor;
    }
  }

  private static resetBackgrounColorForAllNavItems(): void {
    const allNavElements = document.getElementsByClassName('ms-navigation-button');

    Array.from(allNavElements).forEach((navElement) => {
      if (navElement.id != '') {
        document.getElementById(navElement.id).style.background = SWANConstantsEnum.navBackgroundColor;
      }
    });
  }

  private static findNavElement(navId: string): HTMLElement | null {
    const navIdWithEscapedDots = navId.replace('.', '\\.');
    const navElement = document.querySelector(`#vertical-navigation #${navIdWithEscapedDots}`);

    if (!navElement) {
      return null;
    }

    return navElement as HTMLElement;
  }
}
