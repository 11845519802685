import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '../enums';
export class SiteWaterConverter {
  public static convert(
    service: UnitOfMeasureService,
    data: fuse.soilMoisturePrediction[],
    size: unitSizes,
  ): fuse.soilMoisturePrediction[] {
    const newItems: fuse.soilMoisturePrediction[] = [];
    data.forEach((item) => {
      const newItem = {
        siteId: item.siteId,
        dayDate: item.dayDate,
        dayNumber: item.dayNumber,
        dayDisplayMMdd: item.dayDisplayMMdd,
        dayDisplayYMD: item.dayDisplayYMD,
        dayOfWeek: item.dayOfWeek,
        rainfallObs: service.convertFromBase('Fluid Depth', size, item.rainfallObs),
        irrigation: service.convertFromBase('Fluid Depth', size, item.irrigation),
        irrigationPlanned: service.convertFromBase('Fluid Depth', size, item.irrigationPlanned),
        irrigationBudget: service.convertFromBase('Fluid Depth', size, item.irrigationBudget),
        irrigationLocked: item.irrigationLocked,
        soilMoisture: item.soilMoisture,
        soilProbeGraphValue: item.soilProbeGraphValue,
        smOverride: item.smOverride,
        smTargetBase: item.smTargetBase,
        smTargetLow: item.smTargetLow,
        smTargetHigh: item.smTargetHigh,
        soilProbe: item.soilProbe,
        saturationPoint: item.saturationPoint,
        fieldCapacity: item.fieldCapacity,
        wiltingPoint: item.wiltingPoint,
        workingDepth: item.workingDepth,
        raw: service.convertFromBase('Fluid Depth', size, item.raw),
        rawTargetBase: service.convertFromBase('Fluid Depth', size, item.rawTargetBase),
        rawTargetLow: service.convertFromBase('Fluid Depth', size, item.rawTargetLow),
        rawTargetHigh: service.convertFromBase('Fluid Depth', size, item.rawTargetHigh),
        rawWiltingPoint: service.convertFromBase('Fluid Depth', size, item.rawWiltingPoint),
        rawFieldCapacity: service.convertFromBase('Fluid Depth', size, item.rawFieldCapacity),
        rawSaturationPoint: service.convertFromBase('Fluid Depth', size, item.rawSaturationPoint),
        rawSoilProbeGraphValue: service.convertFromBase('Fluid Depth', size, item.rawSoilProbeGraphValue),
      };
      newItems.push(newItem as fuse.soilMoisturePrediction);
    });

    return newItems;
  }
}
