import * as angular from 'angular';

export class UiHelper {
  public static isTabActive(tabs: string[]): boolean {
    const activeTabs = angular.element('md-tab-item.md-active').toArray();
    const tabIds = tabs.map((a) => `id="${a}"`.toLowerCase());

    return tabIds.every((tabId) => activeTabs.some((b) => b.innerHTML.toLowerCase().includes(tabId)));
  }

  public static getMdDatePickerCalendarPanes(): NodeListOf<Element> {
    return document.querySelectorAll('div.md-datepicker-calendar-pane');
  }

  public static handleFocusForMdDatePickerFullScreen(datePickerCalendarPanes: NodeListOf<Element>): void {
    const mdDatePickerFullScreenDiv = document.getElementById('md-date-picker-fullscreen');

    if (!mdDatePickerFullScreenDiv) {
      return;
    }

    datePickerCalendarPanes?.forEach((dpp) => {
      mdDatePickerFullScreenDiv.append(dpp);
    });
  }
}
