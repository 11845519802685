export const enum SQLErrorCodes {
  DuplicateKeyError = 2601,
  UniqueConstraintError = 2627,
  ConstraintCheckError = 547,
}

export const enum unitSizes {
  small = 2,
  normal = 3,
  large = 4,
  huge = 5,
}

export const enum UnitTypes {
  Area = 'Area',
  Chlorine = 'Chlorine Conductivity Ratio',
  Conductivity = 'Conductivity',
  Elevation = 'Elevation',
  FertVolume = 'FertVolume',
  FertVolumeArea = 'FertVolume/Area',
  FlowRate = 'Flow Rate',
  FluidDepth = 'Fluid Depth',
  Length = 'Length',
  LifeForms = 'LifeForms',
  Percent = 'Percent',
  Radionucleotides = 'Radionucleotides',
  SoilDepth = 'Soil Depth',
  SoilMoisture = 'Soil Moisture',
  SolarRadiation = 'Solar Radiation',
  Solubility = 'Solubility',
  SugarContent = 'Sugar Content',
  Temperature = 'Temperature',
  Velocity = 'Velocity',
  Volume = 'Volume',
  VolumeArea = 'Volume/Area',
  WperW = 'W/W',
  Weight = 'Weight',
  WeightArea = 'Weight/Area',
  WeightVolume = 'Weight/Volume',
  Yield = 'Yield',
  YieldArea = 'Yield/Area',
}

export class AmGraphDataValues {
  value: number;
}

export const enum SWANConstantsEnum {
  navBackgroundColor = 'rgb(45,50,62)',
  navDefaultActiveBackgroundColor = 'rgb(3,155,229)',
}

export const enum ApplicationSubscriptions {
  General = 1,
  Nutrients = 2,
  Water = 3,
  Health = 4,
  SysAdmin = 5,
  ScheduleApi = 6,
  IrrigationOptimise = 7,
}

export const enum recalculationStartType {
  default = 0,
  zero = 1,
  pWP = 2,
  fC = 3,
  upperSMBTarget = 4,
  lowerSMBTarget = 5,
  middleSMBTarget = 6,
  sP = 7,
}

export const enum AssetClassCodes {
  Allocation = 'ALLOCATION',
  BudgetNutrientProfile = 'BGT_NT_PRF',
  IrrigationController = 'CONTRL',
  DepthMeter = 'DEPTH_MTR',
  Fertiliser = 'FERTILISR',
  FlowMeter = 'FLOW_METER',
  SiteGroup = 'GROUP',
  SiteHealthPoint = 'HEALTH_PT',
  SiteHealthArea = 'HLTH_AREA',
  NutrientProfile = 'NUTR_PROFL',
  WaterPipe = 'PIPE',
  IrrigationPlan = 'PLAN',
  PlanetGroup = 'PLANET_GRP',
  WaterSamplePoint = 'SAMPLE_PT',
  Site = 'SITE',
  SiteWaterArea = 'SITE_WATER',
  SoilMoisture = 'SOIL_MOIST',
  StemWater = 'STEM_WATR',
  WaterValve = 'VALVE',
  WaterSource = 'WATER_SRC',
  WaterStore = 'WATR_STORE',
  WeatherStation = 'WEATHR_STN',
  WorkAgent = 'WORK_AGENT',
  WaterSupplyManGroup = 'WSM_GROUP',
}

export const enum overrideOption {
  override = 0,
  retain = 10,
  add = 20,
}
