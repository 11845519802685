export class TableControl {
  public total: number;
  public searchKeyword: string;
  public searchField: string;
  public identifierField: string;
  public searchFields: string[];
  public pageSize: number = 20;
  public currentPage: number = 1;
  public orderByField: string;
  public reverseSort: boolean = false;

  public filters = {};
  public statusFilters = { Active: true, Archived: false, Suspended: false };

  public constructor(init?: Partial<TableControl>) {
    Object.assign(this, init);
  }

  public calculateStartFrom(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  public setOrderByColumn(column: string) {
    if (this.orderByField !== column) {
      this.reverseSort = false;
    } else {
      this.reverseSort = !this.reverseSort;
    }
    this.orderByField = column;
  }

  public toggleButton(button: string): void {
    if (this.statusFilters[button] !== null) this.statusFilters[button] = !this.statusFilters[button];
    else this.filters[button] = !this.filters[button];
  }

  public clearFilters() {
    Object.keys(this.filters).forEach((key) => {
      this.filters[key] = '';
    });
  }
}
