import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '../enums';
export class ConvertedSiteIrrigationHistory implements fuse.siteIrrigationHistoryDto {
  dayDisplay: string;
  dayNumber;
  localeDate: Date;
  smb?;
  smbOverride?;
  smbTargetBase;
  smbLowerTarget?;
  smbUpperTarget?;
  raw?;
  rawOverride?;
  rawTargetBase;
  rawLowerTarget?;
  rawUpperTarget?;
  irrigation?;
  irrigationPlanned?;
  irrigationBudget?;
  eto?;
  rainfall?;
  wiltingPointPercent?;
  fieldCapacityPercent?;
  saturationPointPercent?;
  wiltingPointRaw?;
  fieldCapacityRaw?;
  saturationPointRaw?;
  drainage?;
}

export class SiteIrrigationHistoryConverter {
  public static convert(
    service: UnitOfMeasureService,
    data: fuse.siteIrrigationHistoryDto[],
    size: unitSizes,
  ): fuse.siteIrrigationHistoryDto[] {
    const newItems: fuse.siteIrrigationHistoryDto[] = [];

    data.forEach((item) => {
      const newItem = new ConvertedSiteIrrigationHistory();
      newItem.dayDisplay = item.dayDisplay;
      newItem.dayNumber = item.dayNumber;
      newItem.localeDate = item.localeDate;
      newItem.smb = item.smb;
      newItem.smbOverride = item.smbOverride;
      newItem.smbTargetBase = item.smbTargetBase;
      newItem.smbLowerTarget = item.smbLowerTarget;
      newItem.smbUpperTarget = item.smbUpperTarget;
      newItem.raw = service.convertFromBase('Fluid Depth', size, item.raw);
      newItem.rawOverride = service.convertFromBase('Fluid Depth', size, item.rawOverride);
      newItem.rawTargetBase = service.convertFromBase('Fluid Depth', size, item.rawTargetBase);
      newItem.rawLowerTarget = service.convertFromBase('Fluid Depth', size, item.rawLowerTarget);
      newItem.rawUpperTarget = service.convertFromBase('Fluid Depth', size, item.rawUpperTarget);
      newItem.irrigation = service.convertFromBase('Fluid Depth', size, item.irrigation);
      newItem.irrigationPlanned = service.convertFromBase('Fluid Depth', size, item.irrigationPlanned);
      newItem.irrigationBudget = service.convertFromBase('Fluid Depth', size, item.irrigationBudget);
      newItem.eto = service.convertFromBase('Fluid Depth', size, item.eto);
      newItem.rainfall = service.convertFromBase('Fluid Depth', size, item.rainfall);
      newItem.wiltingPointPercent = item.wiltingPointPercent;
      newItem.fieldCapacityPercent = item.fieldCapacityPercent;
      newItem.saturationPointPercent = item.saturationPointPercent;
      newItem.wiltingPointRaw = service.convertFromBase('Fluid Depth', size, item.wiltingPointRaw);
      newItem.fieldCapacityRaw = service.convertFromBase('Fluid Depth', size, item.fieldCapacityRaw);
      newItem.saturationPointRaw = service.convertFromBase('Fluid Depth', size, item.saturationPointRaw);
      newItem.drainage = service.convertFromBase('Fluid Depth', size, item.drainage);

      newItems.push(newItem);
    });

    return newItems;
  }
}
