import { IDropListItem, IIdNameItem } from '@common/models/interfaces';

export abstract class SWANConstants {
  static apiBaseUrl: string;
  static alertRefreshMinutes: number;
  static alertTypes: fuse.alertTypeDto[];
  static assetClasses: fuse.assetClassDto[];
  static currencyCodes: fuse.currencyCodeDto[];
  static dataInputAssetClasses: fuse.dataInputAssetClassDto[];
  static dataInputs: fuse.dataInputDto[];
  static dataProviders: fuse.dataProviderDto[];
  static units: fuse.uomBasic[];
  static uomPreferences: fuse.uomPreferenceDto[];

  // CSV date format must be fixed started with Date, month and year due to the fact that user could enter many date format (free text on CSV).
  // it could be converted by Moment JS into different format. there are so many posibilities of inconsistency.
  // e.g. user input 31/06/2031 if date format 'YYYY/MM/DD' is allowed => Moment JS conversion = 20 June 2031
  // e.g. user input 31/06/2031 if date format 'DD-MMM-YYYY' is allowed => Moment JS conversion = 31 Jan 2006
  static readonly RegexCSVDateFormat = /^\d{1,2}[/]\d{1,2}[/]\d{4}/;
  static readonly RegexDecimalNumber = /^-*\d+(\.\d+)?$/;
  static readonly RegexDecimalNumberWithXDecimals = (dp: number) => new RegExp(`^-*\\d+(\\.\\d{1,${dp}})?$`);
  static readonly RegexDecimalOrWholeNumber = /^-*\d*\.?\d*$/;
  static readonly RegexDecimalOrWholePositiveNumber = /^\d*\.?\d*$/; // e.g 12 or 12.45
  static readonly RegexIntegerNumber = /^-*\d+$/;
  static readonly RegexIntegerPositiveNumber = /^\d+$/;
  static readonly RegexIntegerPositiveRange = /^(\d+)-(\d+)$/;
  static readonly RegexRemoveDoubleQuote = /'/g;
  static readonly RegexSampleWaterObservationValue = /^([<-])?(\d*)$/;
  // Matches any valid HH:mm time input
  static readonly RegexTimeInput = /^(24):(00)$|^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  // Matches any valid HH:mm time input > 00:05
  static readonly RegexTimeInputMin5Minutes = /^(24):(00)$|00:(([1-5][0-9])|(0[5-9]))$|^((1[0-9])|(0[1-9])|2[0-3]):([0-5][0-9])$/;

  static readonly DefaultCountry = 'Australia';
  static readonly DefaultReportingUnits = 'metric';
  static readonly MinDate = new Date(2010, 0, 1);
  static readonly SystemAccountNumber = 1;

  static readonly AddNewCropType = {
    New: 'New',
    Shared: 'Shared',
    Local: 'Local',
    Site: 'Site',
  };

  static readonly AddNewCropStep = {
    Add: 'Add',
    Continue: 'Continue',
    Submit: 'Submit',
    Back: 'Back',
  };

  static readonly BudgetStatus = {
    Suspended: 'Suspended',
    Active: 'Active',
    Archived: 'Archived',
  };

  static readonly CropCoeffUserAction = {
    MoveUp: 'Up',
    MoveDown: 'Down',
    Delete: 'Delete',
    Add: 'Add',
  };

  static readonly CropStressFactor = {
    Poor: 0,
    Average: 1,
    Good: 2,
  };

  static readonly CropTypeStatus = {
    Active: 'Active',
    Archived: 'Archived',
  };

  static readonly FertiliserType = {
    TankMix: 'Tank Mix',
    Raw: 'Raw',
  };

  static readonly HttpCodes = {
    OK: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ACCEPTABLE: 406,
    INTERNAL_SERVER_ERROR: 500,
  };

  static readonly RoleCodes = {
    SYSADMIN: 'SysAdmin',
    ADMIN: 'Admin',
    SUPERVISOR: 'Supervisor',
    AGRONOMIST: 'Agronomist',
    OPERATOR: 'Operator',
    VIEWER: 'Viewer',
  };

  static readonly StylingCode = {
    swanPageTitleBar: 'swanPageTitleBar',
    swanPageTitleBarNew: 'swanPageTitleBarNew',
    swanWidgetTitleBar: 'swanWidgetTitleBar',
    swanWidgetTitleBarNew: 'swanWidgetTitleBarNew',
    swanSectionTitleBar: 'swanSectionTitleBar',
    swanSectionTitleBarNew: 'swanSectionTitleBarNew',
    swanButton: 'swanButton',
    swanButtonNew: 'swanButtonNew',
    swanPrimaryButton: 'swanPrimaryButton',
    swanSecondaryButton: 'swanSecondaryButton',
    swanActiveNavigation: 'swanActiveNavigation',
    NavigationLogoURL: 'NavigationLogoURL',
    DropdownColor: 'DropdownColor',
    DropdownColorNew: 'DropdownColorNew',
  };

  // Arrays
  static readonly AccountRoles: string[] = [
    SWANConstants.RoleCodes.ADMIN,
    SWANConstants.RoleCodes.SUPERVISOR,
    SWANConstants.RoleCodes.AGRONOMIST,
    SWANConstants.RoleCodes.OPERATOR,
    SWANConstants.RoleCodes.VIEWER,
  ];

  static readonly AccountStatuses: string[] = ['Active', 'Suspended', 'Archived'];

  static readonly AlgorithmValueFunctions = [
    { id: 'Min', name: 'Minimum' },
    { id: 'Avg', name: 'Average' },
    { id: 'Max', name: 'Maximum' },
  ] as IIdNameItem[];

  static readonly AssetStatuses: string[] = ['Active', 'Suspended', 'Archived'];

  static readonly CSVDateFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY', 'D/MM/YYYY'];

  // TODO: Base unit measurement should be collected from reference data table.
  static readonly DefaultUnitMeasurements = ['kg', 'l'];

  static readonly LogLevels = [
    { id: 0, name: 'Fatal' },
    { id: 1, name: 'Error' },
    { id: 2, name: 'Warning' },
    { id: 3, name: 'Info' },
    { id: 4, name: 'Debug' },
    { id: 5, name: 'Trace' },
  ] as IDropListItem[];

  static readonly UserRoles: string[] = [
    SWANConstants.RoleCodes.SYSADMIN,
    SWANConstants.RoleCodes.ADMIN,
    SWANConstants.RoleCodes.SUPERVISOR,
    SWANConstants.RoleCodes.AGRONOMIST,
    SWANConstants.RoleCodes.OPERATOR,
    SWANConstants.RoleCodes.VIEWER,
  ];

  static readonly UserStatuses: string[] = ['Active', 'Disabled'];
}
