import { IsoWeekday } from '@indicina/swan-shared/utils/DateUtils';
import { uomUnit } from '@services/unit-of-measure.service';
import { INutrientUsage } from './ProviderPackets';
import { NutrientFields } from 'src/app/_DBContext/NutrientFields';

export interface IContextData {
  accountId: number;
  assetId: number;
  budgetId: number;
  equipmentId: number;
  groupId: number;
  locale: string;
  planId: number;
  siteId: number;
  startDayNumber: number;
  startOfWeek: IsoWeekday;
  tab1: number;
  tab2: number;
  tab3: number;
}
export interface IDashboardParams {
  archivedSites: string;
  siteShapeType: string;
  siteHealthShapeType: string;
  healthQuality: number;
  minimumHealthQuality: number;
  mapHealthIndex: string;
  healthIndexProvider: string;
  healthIndexCloudValue: number;
  healthIndexColourSchemes: healthIndexColourScheme[];
  chartHealthIndex: number;
  overlayOpacity: IOverlayOpacity[];
  overlayType: string;
  baseMapType: string;
  clipToShape: string;
  weatherFormat: string;
  waterUsageAsRate: boolean;
}
export interface AccountInfo {
  accountId: number;
  groupId: number;
  logoBase64: string;
  mapLatitude: number;
  mapLongitude: number;
  mapZoom: number;
  waterGroupId: number;
}
export interface IWaterChartParams {
  soilMoistureUnit: string;
}
export interface IHistoryChartParams {
  soilMoisturePercent: boolean;
}
export interface IOverlayOpacity {
  overlay: string;
  opacity: number;
}
export interface healthIndexColourScheme {
  healthIndex: string;
  palette: string;
  padLeft: number;
  padLeftColour: string;
  padRight: number;
  padRightColour: string;
  numberOfColours: number;
  reverse: boolean;
  paletteType: string;
  useDefault: boolean;
}
export class NutrientDashboard {
  public DisplayData: NutrientInfo[]; // used for the dashboard / chart

  private name: string;

  constructor(pName: string) {
    this.name = pName;

    this.DisplayData = [];
  }

  public reInit(nutArray: NutrientInfo[], unit: uomUnit = null) {
    if (unit) {
      nutArray.forEach((noot) => {
        noot.applied = unit.fromBaseRounded(noot.applied);
        noot.planned = unit.fromBaseRounded(noot.planned);
      });
    }
    this.DisplayData = nutArray;
  }

  // Hack fix because it is too hard to find the problem in breeze or save changes - it will be removed later
  // finds where NaN string is and replaces with 0
  private makeMainNutrientFieldsNaNZero(nutrientUsage: INutrientUsage) {
    Object.keys(nutrientUsage.MainNutrientFields).forEach((i) =>
      Object.keys(nutrientUsage.MainNutrientFields[i]).forEach((j) =>
        nutrientUsage.MainNutrientFields[i][j] === 'NaN'
          ? (nutrientUsage.MainNutrientFields[i][j] = 0)
          : nutrientUsage.MainNutrientFields[i][j],
      ),
    );
    return nutrientUsage;
  }
}

/* Nutrient dashboard structure */
export class NutrientInfo {
  element: string;
  planned: number;
  applied: number;

  constructor(name: string) {
    this.element = name;
    this.planned = 0;
    this.applied = 0;
  }

  public addNutrientUsage(nootUsage: INutrientUsage) {
    const nootFieldBudget = nootUsage.MainNutrientFields.TotalBudget;
    const nootFieldActual = nootUsage.MainNutrientFields.TotalActual;

    this.planned +=
      nootFieldBudget.NO3_N +
      nootFieldBudget.NH4_N +
      nootFieldBudget.P +
      nootFieldBudget.K +
      nootFieldBudget.Ca +
      nootFieldBudget.Mg +
      nootFieldBudget.S +
      nootFieldBudget.Na +
      nootFieldBudget.Cl +
      nootFieldBudget.Cu +
      nootFieldBudget.Fe +
      nootFieldBudget.Mn +
      nootFieldBudget.Zn +
      nootFieldBudget.B +
      nootFieldBudget.Mo;

    this.applied +=
      nootFieldActual.NO3_N +
      nootFieldActual.NH4_N +
      nootFieldActual.P +
      nootFieldActual.K +
      nootFieldActual.Ca +
      nootFieldActual.Mg +
      nootFieldActual.S +
      nootFieldActual.Na +
      nootFieldActual.Cl +
      nootFieldActual.Cu +
      nootFieldActual.Fe +
      nootFieldActual.Mn +
      nootFieldActual.Zn +
      nootFieldActual.B +
      nootFieldActual.Mo;
  }

  private checkElem(element: string, nootUsage: INutrientUsage) {
    this.planned += nootUsage.MainNutrientFields.TotalBudget[element];
    this.applied += nootUsage.MainNutrientFields.TotalActual[element];
  }

  public addElementUsage(element: string, nootUsage: INutrientUsage) {
    if (element === 'N') {
      this.checkElem('NO3_N', nootUsage);
      this.checkElem('NH4_N', nootUsage);
    } else {
      this.checkElem(element, nootUsage);
    }
  }
}

/* Nutrient Report interfaces */
export interface INutrientActuals {
  date: Date;
  nutrients: NutrientFields;
  fertiliserId: number;
  budgted: number;
  actual: number;
}
export interface INutrientActualsFinal {
  dateStr: string;
  nutName: string;
  budgted: number;
  actual: number;
  budgtedCumultive: number;
  actualcumulative: number;
  date: Date; // string;
}

export interface IDropListItem {
  id: number;
  name: string;
}
export interface IIntDropListItem {
  id: number;
  value: number;
}
export interface IBooleanDropListItem {
  id: boolean;
  name: string;
}
export interface IStringDropListItem {
  id: string;
  name: string;
}
export interface ICsvLogItem {
  date: string;
  level: string;
  id: string;
  comment: string;
}
export interface IIdNameItem {
  id: number | string;
  name: string;
  value: string | number | boolean | Date;
  description: string;
}
export interface IIdValueItem {
  id: number | string;
  value: number;
}
