import { UnitOfMeasureService } from '@services/unit-of-measure.service';
import { unitSizes } from '../enums';
import { DateUtils } from '@indicina/swan-shared/utils/DateUtils';

export class SiteConvertedWaterUsageConverter {
  public static convert(service: UnitOfMeasureService, data: any[], size: unitSizes): fuse.WaterUsage[] {
    const newItems: fuse.WaterUsage[] = [];

    data.forEach((item) => {
      const newItem = {
        localeDate: item.localeDate,
        dateDisplay: DateUtils.Locale.asDateDayAndMonth(item.localeDate),
        week: item.week,
        budget: service.convertFromBase('Volume', size, Number(item.budget)),
        actual: service.convertFromBase('Volume', size, Number(item.actual)),
        planned: service.convertFromBase('Volume', size, Number(item.planned)),
        budgetRunning: service.convertFromBase('Volume', size, Number(item.budgetRunning)),
        actualRunning: service.convertFromBase('Volume', size, Number(item.actualRunning)),
        projectedRunning: service.convertFromBase('Volume', size, Number(item.projectedRunning)),
        budget_rate: item.budget_rate,
        actual_rate: item.actual_rate,
        budgetRunning_rate: item.budgetRunning_rat,
        actualRunning_rate: item.actualRunning_rate,
      } as unknown as fuse.WaterUsage;

      newItems.push(newItem);
    });

    return newItems;
  }
}
